import * as React from 'react';
import DownloadButton from '../components/DownloadButton';
import axios from 'axios';
import dayjs from 'dayjs';
import Chat1 from '../../static/chat1.png';
import Chat2 from '../../static/chat2.png';
import Chat3 from '../../static/chat3.png';
import Footer from '../components/footer';
import Desktop from '../../static/desktop.jpg';
import sal from 'sal.js';
import Seo from '../components/seo';
import { OutboundLink } from 'gatsby-plugin-google-analytics';

const IndexPage = () => {
  const [todaysArt, setTodaysArt] = React.useState(null);
  const [yesterdaysArt, setYesterdaysArt] = React.useState(null);

  React.useEffect(() => {
    const today = dayjs().format('YYYY-MM-DD');
    const yesterday = dayjs().subtract(1, 'day').format('YYYY-MM-DD');
    axios
      .get(`https://api.artdiario.com/api/art/${today}`)
      .then((res) => {
        const data = res.data[0];
        const artUrl = data.imageUrl.replace('original', 'resized');
        setTodaysArt({
          ...todaysArt,
          title: data.artTitle,
          artist: data.artistName,
          image: artUrl,
          link: data.externalLink,
        });
      })
      .catch((err) => {
        console.log(err);
      });

    axios
      .get(`https://api.artdiario.com/api/art/${yesterday}`)
      .then((res) => {
        const data = res.data[0];
        const artUrl = data.imageUrl.replace('original', 'resized');
        setYesterdaysArt({
          ...yesterdaysArt,
          title: data.artTitle,
          artist: data.artistName,
          image: artUrl,
          link: data.externalLink,
        });
      })
      .catch((err) => {
        console.log(err);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    // scroll library https://github.com/mciastek/sal
    sal({
      threshold: 0.1,
    });
  }, [todaysArt, yesterdaysArt]);

  const year = dayjs().format('YYYY');

  return (
    <div className="px-4 sm:w-9/12 mx-auto">
      <Seo />
      <h1 className="text-2xl font-extrabold mx-auto text-center py-10 border-b border-slate-200 w-9/12">
        Art Diario
      </h1>
      <h2
        className="text-3xl sm:text-5xl font-extrabold mx-auto text-center mt-10 mb-8"
        data-sal-duration="500"
        data-sal="slide-up"
        data-sal-delay="200"
        data-sal-easing="ease-in-out"
      >
        Be exposed to great art
      </h2>
      <h3
        className="text-xl font-semibold mx-auto text-center text-slate-500 sm:w-5/12 mb-8"
        data-sal-duration="400"
        data-sal="slide-up"
        data-sal-delay="500"
        data-sal-easing="ease-in-out"
      >
        Meet Art Diario (that second part is Spanish, dee-arr-ee-o!). A simple
        MacOS app that changes your wallpaper to a new piece of art each day.
      </h3>
      <DownloadButton />
      {todaysArt && (
        <div
          className="my-10 drop-shadow-art min-h-[25rem] will-change-transform"
          data-sal-duration="300"
          data-sal="fade"
          data-sal-delay="1000"
          data-sal-easing="ease-in-out"
        >
          <img className="w-full" src={todaysArt.image} alt={todaysArt.title} />
          <div className="bg-black p-6 sm:p-10">
            <h2 className="font-bold text-slate-50 text-xl sm:text-2xl">
              {todaysArt.artist}
            </h2>
            <h2 className="font-bold text-slate-500 text-lg">
              {todaysArt.title}
            </h2>
            <h2 className="font-bold text-amber-600 underline">
              <OutboundLink href={todaysArt.link} target="_blank">
                Find out more
              </OutboundLink>
            </h2>
          </div>
        </div>
      )}
      <div className="grid gap-8 sm:gap-14 grid-cols-12 place-items-center my-20">
        <h2
          className="col-span-5 text-xl sm:text-2xl sm:text-4xl font-bold text-slate-500 w-10/12"
          data-sal-duration="300"
          data-sal="slide-up"
          data-sal-delay="400"
          data-sal-easing="ease-in-out"
        >
          Just one piece of hand-curated art per day
        </h2>
        {yesterdaysArt && (
          <div className="col-span-7">
            <img
              src={yesterdaysArt.image}
              alt={yesterdaysArt.title}
              className="w-full drop-shadow-xl"
              data-sal-duration="300"
              data-sal="fade"
              data-sal-delay="300"
              data-sal-easing="ease-in-out"
            />
            <h2 className="text-center py-4 font-semibold text-slate-500">
              {yesterdaysArt.artist} - {yesterdaysArt.title}
            </h2>
          </div>
        )}
      </div>
      <div className="grid gap-8 sm:gap-14 grid-cols-12 place-items-center my-20">
        <div className="col-span-7">
          <img
            src={Chat1}
            className="w-5/6 sm:w-4/6"
            alt="Chat: Did you see the art today?"
            data-sal-duration="200"
            data-sal="slide-up"
            data-sal-delay="200"
            data-sal-easing="ease-in-out"
          />
          <img
            src={Chat2}
            alt="Chat: Stunning, I’d never heard of this artist before"
            className="my-8 ml-6 sm:ml-8"
            data-sal-duration="200"
            data-sal="slide-up"
            data-sal-delay="500"
            data-sal-easing="ease-in-out"
          />
          <img
            src={Chat3}
            className="w-4/6 sm:w-3/6"
            alt="Me neither..."
            data-sal-duration="200"
            data-sal="slide-up"
            data-sal-delay="800"
            data-sal-easing="ease-in-out"
          />
        </div>

        <h2
          className="col-span-5 text-xl sm:text-4xl font-bold text-slate-500 w-10/12"
          data-sal-duration="300"
          data-sal="fade"
          data-sal-delay="200"
          data-sal-easing="ease-in-out"
        >
          Everyone sees the same art, so you can talk about it
        </h2>
      </div>
      <h2 className="text-3xl sm:text-5xl font-extrabold mx-auto text-center mt-10 mb-8">
        What does it look like?
      </h2>
      <h3 className="text-xl font-semibold mx-auto text-center text-slate-500 sm:w-5/12 mb-8">
        It lives up in the menu bar on your desktop behind a cool Art icon that
        you can click for more detail.
      </h3>
      <img
        src={Desktop}
        alt="What it looks like on your desktop"
        data-sal-duration="300"
        data-sal="fade"
        data-sal-delay="100"
        data-sal-easing="ease-in-out"
      />
      <h2 className="text-3xl sm:text-5xl font-extrabold mx-auto text-center mt-10 mb-8">
        FAQ
      </h2>
      <div className="sm:w-10/12 mx-auto">
        <div className="grid gap-4 sm:gap-14 grid-cols-1 sm:grid-cols-10 my-20">
          <div className="col-span-4 font-bold">Can I change the art?</div>
          <div className="col-span-6">
            Nope. There’s only one piece of hand-curated art per day, and
            everyone sees the same art.{' '}
          </div>
        </div>
        <div className="grid gap-4 sm:gap-14 grid-cols-1 sm:grid-cols-10 my-20">
          <div className="col-span-4 font-bold">Why?</div>
          <div className="col-span-6">
            I like the effect. You passively experience new art every day
            without having a choice in the matter. It makes it so everyone who
            has the app can chat about it.
          </div>
        </div>
        <div className="grid gap-4 sm:gap-14 grid-cols-1 sm:grid-cols-10 my-20">
          <div className="col-span-4 font-bold">Who curates the art?</div>
          <div className="col-span-6">
            Me and my wife do. Sometimes a friend will make a suggestion.
          </div>
        </div>
        <div className="grid gap-4 sm:gap-14 grid-cols-1 sm:grid-cols-10 my-20">
          <div className="col-span-4 font-bold">Can I suggest a piece?</div>
          <div className="col-span-6">
            Hell yeah, there just needs to be a really high resolution version
            of it available. Submit it here:{' '}
            <OutboundLink
              className="text-blue-600 hover:underline"
              href="https://forms.gle/n7j1MJ2xSWk7bX9s7"
            >
              Art Suggestions
            </OutboundLink>
          </div>
        </div>
        <div className="grid gap-4 sm:gap-14 grid-cols-1 sm:grid-cols-10 my-20">
          <div className="col-span-4 font-bold">
            I want to see the whole art, why is it cropped?
          </div>
          <div className="col-span-6">
            This was originally to scratch my own itch, and I liked to see the
            art up close in a way that filled my whole desktop. A lot of art is
            vertically oriented, and it would be rendered in a way that would
            make the details extremely small on a computer screen.
          </div>
        </div>
        <div className="grid gap-4 sm:gap-14 grid-cols-1 sm:grid-cols-10 my-20">
          <div className="col-span-4 font-bold">
            I still want to see the whole piece
          </div>
          <div className="col-span-6">
            Fine, fine! I get it! I have plans to make it a setting in the
            future. Please let me know you want it here:{' '}
            <OutboundLink
              className="text-blue-600 hover:underline"
              href="https://forms.gle/iKpSgKFa3P7b6cfT6"
            >
              Feature Suggestions
            </OutboundLink>
          </div>
        </div>
        <div className="grid gap-4 sm:gap-14 grid-cols-1 sm:grid-cols-10 my-20">
          <div className="col-span-4 font-bold">How does this make money?</div>
          <div className="col-span-6">
            It doesn’t, but it’s pretty cheap to operate (for now at least). I
            kinda like that it doesn’t make money or want to make money.
          </div>
        </div>
        <div className="grid gap-4 sm:gap-14 grid-cols-1 sm:grid-cols-10 my-20">
          <div className="col-span-4 font-bold">How/why did you make this?</div>
          <div className="col-span-6">
            I go into an unnecessary amount of detail{' '}
            <OutboundLink
              className="text-blue-600 hover:underline"
              href="https://www.jimmyhooker.com/blog/art-diario-the-most-complicated-thing-ive-built/"
            >
              here
            </OutboundLink>
            .
          </div>
        </div>
        <div className="grid gap-4 sm:gap-14 grid-cols-1 sm:grid-cols-10 my-20">
          <div className="col-span-4 font-bold">I want to contact you</div>
          <div className="col-span-6">
            Sure, you can reach me at jimmy@artdiario.com
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default IndexPage;
